class ContantValues {
  TEXTFIELD_ONE_ROW_HEIGHT = 23;
  API_BASE_URLS = {
    dev: 'https://pi-backend-dev-401883a6ed36.herokuapp.com',
    stage: 'https://pi-backend-stage-983c522c7c19.herokuapp.com',
    production: 'https://pi-backend-prod-cd0ba6433021.herokuapp.com',
  };
  SOCKET_BASE_URLS = {
    dev: 'https://talkwise-backend-dev.herokuapp.com',
    stage: 'https://talkwise-backend-stage.herokuapp.com',
    production: 'https://talkwise-backend-prod.herokuapp.com',
  };
  POSTHOG_FEATURE_FLAG = {
    trialDays: 'trial-days-comparison',
    subscriptionPricing: 'subscription-pricing-comparison',
  };
}

export const Constants = new ContantValues();

export const videoContent = [
  {
    title: 'Intro Video',
    url: 'https://www.youtube.com/watch?v=LuYG9-AAsD4',
    summary:
      'An overview of the course structure and how it will help you master job interviews.',
    transcript:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin bibendum nec odio vel tincidunt. Integer facilisis ultrices nulla, sed varius elit ullamcorper non. Suspendisse vel nibh sed sapien egestas malesuada.',
  },
  {
    title: 'Why?',
    url: 'https://www.youtube.com/watch?v=Y-rypWemYU0',
    summary:
      'Discover the importance of knowing your "why" before heading into an interview.',
    transcript:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In tincidunt elit nec elit iaculis, in lacinia purus malesuada. Sed quis enim eros. Nullam sed eros non magna tempor finibus.',
  },
  {
    title: 'Mindset',
    url: 'https://www.youtube.com/watch?v=SJupjoSwDPo',
    summary:
      'Learn how cultivating a strong and confident mindset can transform your interviews.',
    transcript:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus scelerisque, orci nec blandit efficitur, velit risus fermentum quam, vel viverra erat diam vitae nulla.',
  },
  {
    title: 'Breathing',
    url: 'https://www.youtube.com/watch?v=bGGJfM8Ah2k',
    summary:
      'Use breathing techniques to stay calm and focused during interviews.',
    transcript:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed facilisis libero ac orci rhoncus, sed vestibulum nisi aliquam. Morbi id metus ac nunc bibendum convallis.',
  },
  {
    title: 'Body Language',
    url: 'https://www.youtube.com/watch?v=xcXK-dUtaEE',
    summary: 'Master nonverbal cues to present confidence and professionalism.',
    transcript:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce condimentum ligula a velit hendrerit, vitae rutrum sapien porttitor.',
  },
  {
    title: 'Pitch & Tone',
    url: 'https://www.youtube.com/watch?v=4cJL8db6vxU',
    summary:
      'Adjust your pitch and tone to sound more confident and persuasive.',
    transcript:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris non sem a lacus vestibulum euismod vel at justo.',
  },
  {
    title: 'First Impressions',
    url: 'https://www.youtube.com/watch?v=pWYd3-WmU5s',
    summary:
      'Create a lasting and positive first impression in the first few minutes.',
    transcript:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean faucibus ex in magna vestibulum, ut luctus lorem laoreet.',
  },
  {
    title: 'Research (General)',
    url: 'https://www.youtube.com/watch?v=6Tw6Kwxulqc',
    summary:
      'Understand how to research companies effectively before an interview.',
    transcript:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec efficitur metus nec mi commodo, a luctus magna fermentum.',
  },
  {
    title: 'Relationships (Connections)',
    url: 'https://www.youtube.com/watch?v=aVafqTxjKnk',
    summary:
      'Learn to leverage relationships and connections for deeper insights into roles.',
    transcript:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque pharetra metus et vehicula sodales.',
  },
  {
    title: 'Research (Point of Contact)',
    url: 'https://www.youtube.com/watch?v=YKlg_okyYPk',
    summary:
      'Discover strategies for researching and connecting with your interviewers.',
    transcript:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed a justo vitae turpis tincidunt posuere.',
  },
  {
    title: 'The Job Description',
    url: 'https://www.youtube.com/watch?v=89DinvMvVv0',
    summary:
      'Break down a job description to tailor your answers with precision.',
    transcript:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur tincidunt sapien nec velit tempor tincidunt.',
  },
  {
    title: 'Strengths Mapping',
    url: 'https://www.youtube.com/watch?v=w8dLEtWJ-EI',
    summary: 'Map your strengths to what the employer is actually looking for.',
    transcript:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum eget arcu vel dolor rhoncus laoreet.',
  },
  {
    title: 'Visuals',
    url: 'https://www.youtube.com/watch?v=Xj4tXJAC9KU',
    summary:
      'Use visuals effectively to reinforce your personal brand and skills.',
    transcript:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In hac habitasse platea dictumst.',
  },
  {
    title: 'Interviewer Attention Span',
    url: 'https://www.youtube.com/watch?v=STfCVDLdbvc',
    summary: 'Craft concise answers that keep the interviewer engaged.',
    transcript:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent porttitor lorem in diam vestibulum, a lacinia justo dapibus.',
  },
  {
    title: 'Using Interview Methods',
    url: 'https://www.youtube.com/watch?v=VOXvDA-R3J8',
    summary:
      'Apply proven interview methods like STAR and CAR to structure your answers.',
    transcript:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer porta nulla nec nisi bibendum ultrices.',
  },
  {
    title: 'Write Down & Restate the Question',
    url: 'https://www.youtube.com/watch?v=YLpMDLWXc0Y',
    summary:
      'Learn a technique to clarify and buy time when answering tough questions.',
    transcript:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras suscipit lacus non ante pulvinar, id dictum nisi viverra.',
  },
  {
    title: 'Video Interview Cheat Sheet',
    url: 'https://www.youtube.com/watch?v=X-p3MEW8pPA',
    summary:
      'Quick tips to ace video interviews with a professional setup and delivery.',
    transcript:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed a dui in quam dignissim ullamcorper.',
  },
];
